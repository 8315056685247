import { OpenInNew } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import Modal from 'src/components/shared/Modal';
import QRCodeGenerator from 'src/components/shared/qrCodeGenerator';
import useModalStore from 'src/lib/stores/useModalStore';

type PreviewQrCodeModalProps = {
  url: string;
  title: string;
  size?: number;
  hideOpenButton?: boolean;
};
export default function PreviewQrCodeModal({
  url,
  title,
  size,
  hideOpenButton = false,
}: PreviewQrCodeModalProps): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const handleOpenLink = () => {
    window.open(url, '_blank');
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardHeader title={title} />
        <CardContent sx={{ textAlign: 'center' }}>
          <QRCodeGenerator url={url} size={256} />

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            mt={2}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseModal}
              size="large"
            >
              Cancel
            </Button>
            {!hideOpenButton && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleOpenLink}
              >
                <OpenInNew /> Go to Link
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
