import { OpenInNew } from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import PreviewQrCodeModal from 'src/components/shared/QrCodeUrlPreviewer/QrCodeModal';
import useModalStore from 'src/lib/stores/useModalStore';

interface QrCodeUrlPreviewerProps {
  title: string;
  url: string;
  size?: number;
  hideUrl?: boolean;
  hideOpenButton?: boolean;
}

export default function QrCodeUrlPreviewer({
  hideUrl = false,
  title,
  url,
  size,
  hideOpenButton = false,
}: QrCodeUrlPreviewerProps) {
  const { openModal } = useModalStore();

  const handleOpenQrCodeModal = async () => {
    openModal(
      <PreviewQrCodeModal
        title={title}
        url={url}
        size={size}
        hideOpenButton={hideOpenButton}
      />
    );
  };

  return (
    <Flex>
      {!hideUrl && (
        <Link
          href={url}
          onClick={(e) => {
            e.preventDefault();
            handleOpenQrCodeModal();
          }}
        >
          {url}
        </Link>
      )}

      <IconButton color="primary" size="small" onClick={handleOpenQrCodeModal}>
        <OpenInNew />
      </IconButton>
    </Flex>
  );
}
